import React, { FC } from 'react'
import { DateInput2, DateInput2Props } from '@blueprintjs/datetime2'
import CN from 'classnames'
import { format, parse, subYears } from 'date-fns'

import './CalendarInput.scss'

export interface CalendarInputProps extends DateInput2Props {
  defaultValue?: string
  maxDate?: Date
  minDate?: Date
  value?: string
  highlightCurrentDay?: boolean
  showAction?: boolean
  onChange?: (newDate: string | null, isUserChange: boolean) => void
  className?: string
  placeHolder?: string
  isErrored?: boolean
  disableFutureDates?: boolean
  helperText?: string
  [x: string]: any
}

export const CalendarInput: FC<CalendarInputProps> = ({
  className,
  onChange,
  value,
  placeHolder,
  isErrored = false,
  disableFutureDates,
  minDate,
  defaultValue,
  helperText,
  ...restProps
}: CalendarInputProps) => {
  const CalendarInputClasses = CN(`calendar-picker shadow-sm`, className, {})

  /** helper text style */
  const helperTextClassName = CN('text-small font-Regular pt-1', {
    'text-Gray-500': !isErrored,
    'text-Red-600': isErrored,
  })
  return (
    <div className={CalendarInputClasses}>
      <DateInput2
        className={CN(className, {
          'border border-Red-400 rounded-md': isErrored,
        })}
        onChange={onChange}
        placeholder={placeHolder}
        maxDate={disableFutureDates ? new Date() : undefined}
        minDate={minDate || subYears(new Date(), 100)}
        rightElement={
          <i
            className={
              'text-small ri-calendar-line absolute text-Gray-500 top-[10px] right-[12px]'
            }
          />
        }
        value={value}
        defaultValue={defaultValue}
        {...restProps}
      />
      {/* helper text */}
      {helperText !== undefined && (
        <span className={helperTextClassName}>{helperText}</span>
      )}
    </div>
  )
}

CalendarInput.defaultProps = {
  defaultValue: new Date().toDateString(),
  highlightCurrentDay: true,
  formatDate: (date) => format(date, 'dd/mm/yyyy'),
  parseDate: (str) => parse(str, 'dd/mm/yyyy', new Date()),
  placeholder: 'dd/mm/yyyy',
  disableFutureDates: false,
  helperText: undefined,
}

export default CalendarInput
