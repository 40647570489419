import { Modal } from 'components/molecules/Modal'

import '../modal/modal.scss'

type ReachedTimeModalProps = {
  showModal: boolean
  primaryAction: () => void
  secondaryAction: () => void
  closeButtonAction: () => void
  primaryBtnName: string
  secondaryBtnName: string
  isSavingAssessment: boolean
  isLoadingDeclineAssessment: boolean
}

const ReachedTimeModal = ({
  showModal,
  primaryAction,
  secondaryAction,
  closeButtonAction,
  primaryBtnName,
  secondaryBtnName,
  isSavingAssessment,
  isLoadingDeclineAssessment,
}: ReachedTimeModalProps) => {
  const isButtonLoading = isLoadingDeclineAssessment || isSavingAssessment

  return (
    <Modal
      isActive={showModal}
      headerTitle=''
      onClickPrimaryBtn={primaryAction}
      secondaryButtonLoading={isButtonLoading}
      secondaryButtonDisable={isButtonLoading}
      primaryButtonDisable={isButtonLoading}
      onHeaderCloseButtonClick={() => {
        !isSavingAssessment &&
          !isLoadingDeclineAssessment &&
          closeButtonAction()
      }}
      onClickSecondaryBtn={secondaryAction}
      primaryButtonProps={{
        style: { width: '100%', marginRight: '12px' },
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      //   onOverlayClick={secondaryAction}
      modalProps={{
        className: 'w-[700px] md:w-[600px]  bg-white',
      }}
      footerProps={{
        style: {
          justifyContent: 'end',
          paddingLeft: '23px',
          paddingRight: '23px',
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonTitle={primaryBtnName}
      secondaryButtonTitle={secondaryBtnName}>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-[#FFF6EC]'>
          <i className='ri-error-warning-line text-[#DD7F0B] text-heading-3'></i>
        </div>
        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Assessment Time Limit Reached
        </span>
        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          The assessment duration has ended. You have the option to either
          complete the assessment now or decline it, which will delete any
          entered information.
        </span>
      </div>
    </Modal>
  )
}

export default ReachedTimeModal
