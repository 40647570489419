import { useCallback, useEffect, useState } from 'react'

interface FullscreenState {
  isFullscreen: boolean
  showFullscreenWarningModal: boolean
}

const fullscreenChangeEvents = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange',
]
const fullscreenElementProperties = [
  'fullscreenElement',
  'webkitFullscreenElement',
  'mozFullScreenElement',
  'msFullscreenElement',
]

const exitFullscreenMethods = [
  'exitFullscreen',
  'webkitExitFullscreen',
  'mozCancelFullScreen',
  'msExitFullscreen',
]

const requestFullscreenMethods = [
  'requestFullscreen',
  'webkitRequestFullscreen',
  'mozRequestFullScreen',
  'msRequestFullscreen',
]

export const useFullscreen = (): FullscreenState & {
  enterFullscreen: () => void
  exitFullscreen: () => void
} => {
  const [fullscreenState, setFullscreenState] = useState<FullscreenState>({
    isFullscreen: false,
    showFullscreenWarningModal: false,
  })

  const enterFullscreen = useCallback(() => {
    const element = document.documentElement

    requestFullscreenMethods.some((method) => {
      if (element[method]) {
        element[method]()
          .then(() => {
            setFullscreenState({ ...fullscreenState, isFullscreen: true })
          })
          .catch((error) => {
            console.error('Failed to enter fullscreen mode:', error)
          })
        return true
      }
      return false
    })
  }, [])

  const exitFullscreen = useCallback(() => {
    if (fullscreenElementProperties.some((property) => document[property])) {
      exitFullscreenMethods.some((method) => {
        if (document[method]) {
          document[method]()
            .then(() => {
              setFullscreenState({ ...fullscreenState, isFullscreen: false })
            })
            .catch((error) => {
              console.error('Failed to exit fullscreen mode:', error)
            })
          return true
        }
        return false
      })
    }
  }, [])

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreen =
        fullscreenElementProperties.some((property) => !!document[property]) &&
        !fullscreenState.isFullscreen

      if (isFullscreen) {
        setFullscreenState({
          ...fullscreenState,
          isFullscreen: true,
          showFullscreenWarningModal: false,
        })
      } else {
        setFullscreenState({
          ...fullscreenState,
          isFullscreen: false,
          showFullscreenWarningModal: true,
        })
      }
    }

    fullscreenChangeEvents.forEach((event) => {
      document.addEventListener(event, handleFullscreenChange)
    })

    return () => {
      fullscreenChangeEvents.forEach((event) => {
        document.removeEventListener(event, handleFullscreenChange)
      })
    }
  }, [])

  useEffect(() => {
    enterFullscreen() // Ensure it's user-triggered
    return () => exitFullscreen()
  }, [enterFullscreen, exitFullscreen])

  return {
    ...fullscreenState,
    enterFullscreen,
    exitFullscreen,
  }
}
