import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type DynamicAssessmentFormType = {
  messageTitle: string
  message: string
  jsonSchema: any
  jsonUiSchema: string
  templateName?: string
  primaryUrl?: string
  secondaryUrl?: string
}

const getDynamicAssessmentForm = async (
  templateId: string,
): Promise<DynamicAssessmentFormType> => {
  if (templateId) {
    const res = await get(
      `assessment/${templateId}/isExam/${true}/GetDynamicAssessmentForm`,
    )
    return res.data as DynamicAssessmentFormType
  }
  throw new Error('Template ID is required')
}

export const useGetDynamicAssessmentForm = (templateId: string) => {
  return useQuery(
    ['GET_DYNAMIC_ASSESSMENT_FORM', templateId],
    () => getDynamicAssessmentForm(templateId),
    {
      enabled: !!templateId,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching dynamic form',
          status: 'Error',
        })
      },
    },
  )
}
