import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import JSONForm from '@rjsf/core'
import { useGetMappedVariables } from 'api'
import RefHubLogo from 'assets/images/refHubLogo.svg'
import { useSubmitAssessment } from 'framework/api/methods/assessment/submitAssessment'
import { useGetDynamicAssessmentForm } from 'framework/api/methods/assessment/useGetDynamicAssessmentForm'
import { ListAssessmentResponse } from 'framework/api/methods/assessment/useGetInitialAssessmentDetailsById'
import { useDisableCopyPaste, useFullscreen } from 'hooks'
import { setWordList } from 'store/reducers/typeTest/wordSlice'

import { PageLoader } from 'components/atoms'
import {
  FormBuilder,
  modifyParagraphOrHeadingSchema,
} from 'components/molecules'
import { assessmentTypes } from 'utils/assessmentTypes'

import PersonalityTestAddon from '../Addons/PersonalityTestAddon'
import TypeTestAddon from '../Addons/TypeTestAddon'
import useOutsideMouseTracker from '../hook/useOutsideMouseTracker'
import ProgressBar from '../ProgressBar/ProgressBar'
import Timer, { TimerHandle } from '../Timer/Timer'

import ExitFullScreenModal from './ExitFullScreenModal'

type QuestionsProps = {
  initialAssessment: ListAssessmentResponse | null
  maxSnaps: number
  captureImage: () => void
  handleAssessmentDecline: () => void
  isLoadingDeclineAssessment: boolean
  verifyAddons: () => void
  candidateId: string
  assessmentId: string
  [x: string]: any
  isAssessmentRunning: boolean
  setIsAssessmentRunning: Dispatch<SetStateAction<boolean>>
}

const Questions = ({
  className,
  initialAssessment,
  maxSnaps,
  captureImage,
  handleAssessmentDecline,
  isLoadingDeclineAssessment,
  verifyAddons,
  candidateId,
  assessmentId,
  isAssessmentRunning,
  setIsAssessmentRunning,
}: QuestionsProps) => {
  const disableCopyPasteRef = useDisableCopyPaste()
  const { showFullscreenWarningModal, enterFullscreen } = useFullscreen()
  const { timeOutside, formatTime, setIsOutOfTheFullScreen } =
    useOutsideMouseTracker()
  //   console.log(timeOutside)

  const [dynamicFieldMapping, setDynamicFieldMapping] = useState<Record<
    string,
    string
  > | null>(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [formData, setFormData] = useState({})
  const componentRef = useRef<HTMLDivElement>(null)
  const dynamicFormRef = useRef<JSONForm<any, any> | null>(null)
  const [dynamicFormSchema, setDynamicFormSchema] = useState<any>(null)
  const [totalAssessmentCount, setTotalAssessmentCount] = useState(0)
  const [totalSteps, setTotalSteps] = useState(0)
  const [isFieldValid, setIsFieldValid] = useState(false)
  const [formattedTime, setFormattedTime] = useState<any>(null)
  const [propertyKey, setPropertyKey] = useState<any>(null)
  const [addonSubmitOnExit, setAddonSubmitOnExit] = useState(false)
  const [isLogoLoading, setIsLogoLoading] = useState(true)

  const [formKey, setFormKey] = useState(0)
  const timerRef = useRef<TimerHandle>(null)

  useGetMappedVariables({ candidateId: candidateId ?? '' }, (data) => {
    setDynamicFieldMapping(data)
  })

  const { data: dynamicForm, isLoading: isLoadingDynamicForm } =
    useGetDynamicAssessmentForm(assessmentId ?? '')

  const dispatch = useDispatch()
  const { mutateAsync: submitAssessment, isLoading: isSavingAssessment } =
    useSubmitAssessment()

  // Handle typing test words
  useEffect(() => {
    if (
      dynamicFormSchema?.required?.length > 0 &&
      initialAssessment?.assessmentName === assessmentTypes.TYPING_TEST
    ) {
      const req = dynamicFormSchema?.required[0]
      const words = dynamicFormSchema?.properties?.[req]?.title
      if (words) {
        const formattedWords = words.replace(/^01\.\s*/, '').toLowerCase()
        dispatch(setWordList(formattedWords))
      }
    }
  }, [dynamicFormSchema, initialAssessment])

  // Auto resize textareas
  useEffect(() => {
    const handleTextareaInput = (event) => {
      const textarea = event.target as HTMLTextAreaElement
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
      textarea.style.marginBottom = '12px'
    }

    const textareas = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('textarea')
    if (textareas) {
      textareas.forEach((textarea) => {
        if (textarea instanceof HTMLTextAreaElement) {
          textarea.rows = 5
          textarea.addEventListener('input', handleTextareaInput)
        }
      })
    }

    return () => {
      if (textareas) {
        textareas.forEach((textarea) => {
          if (textarea instanceof HTMLTextAreaElement) {
            textarea.removeEventListener('input', handleTextareaInput)
          }
        })
      }
    }
  }, [dynamicForm])

  // Appending form elements to containers
  useEffect(() => {
    const updateNode = () => {
      if (!componentRef.current) {
        return
      }

      const containers = Array.from(
        componentRef.current.querySelectorAll('.append-class-container'),
      )

      const elementsToAppend = Array.from(
        componentRef.current.querySelectorAll(
          '.form-control, .checkboxes, .field-radio-group',
        ),
      )

      if (containers.length > 0 && elementsToAppend.length > 0) {
        elementsToAppend.forEach((element, index) => {
          const containerIndex = index % containers.length
          const container = containers[containerIndex]

          if (element && container) {
            if (!container.contains(element)) {
              element.classList.add('w-full')
              try {
                container.appendChild(element)
              } catch (error) {
                console.error('Error appending element:', error)
              }
            }
          }
        })
      } else {
        console.log('No containers or elements to append found')
      }
    }

    updateNode()
  }, [dynamicForm, currentStep, dynamicFormSchema, propertyKey])

  // Update schema and form state
  useEffect(() => {
    if (dynamicForm) {
      setDynamicFormSchema(changeJsonSchema(JSON.parse(dynamicForm.jsonSchema)))
    }
  }, [dynamicForm, currentStep])

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1)
  }, [dynamicForm, currentStep])

  // Update schema with dynamic field mapping and other modifications
  const changeJsonSchema = (schema: any) => {
    const properties = { ...schema.properties }
    const propertiesKey = Object.keys(properties)
    const propertiesValue = Object.values(properties)
    const filteredProperties =
      propertiesValue &&
      propertiesValue.filter((obj: any) => obj['title'] !== '')

    setTotalAssessmentCount(propertiesKey.length)

    // Set total steps based on assessment type
    if (
      initialAssessment?.assessmentName !== assessmentTypes.TYPING_TEST &&
      initialAssessment?.assessmentName !== assessmentTypes.PERSONALITY_TEST
    ) {
      setTotalSteps(filteredProperties.length)
    }

    const currentFieldKey = Object.keys(properties)[currentStep]
    const isRequired = schema.required?.includes(currentFieldKey)
    setIsFieldValid(!isRequired)

    let tempIndex = 0

    if (dynamicFieldMapping) {
      Object.keys(properties).forEach((key) => {
        const property = properties[key]
        Object.entries(dynamicFieldMapping).forEach(([code, value]) => {
          if (property?.title !== '') {
            property.title = property.title.replaceAll(`@${code}`, value)
          }
          if (property?.title === '' && property?.description !== '') {
            property.description = property.description.replaceAll(
              `@${code}`,
              value,
            )
          }
        })
        if (property?.type !== 'object') {
          property.title = `${tempIndex < 9 ? '0' : ''}${tempIndex + 1}. ${
            property.title
          }`
          tempIndex++
        }
      })
    }

    const visiblePropertyKey = Object.keys(properties)[currentStep]
    setPropertyKey(visiblePropertyKey)

    const updatedSchema = {
      ...schema,
      properties: {
        [visiblePropertyKey]: properties[visiblePropertyKey],
      },
    }

    return updatedSchema
  }

  const isLastStep = currentStep === totalAssessmentCount - 1

  // Handle form changes
  const handleChange = ({ formData }) => {
    setFormData(formData)
    const currentFieldKey = Object.keys(dynamicFormSchema.properties)[0]
    const currentFieldValue: any = Object.values(
      dynamicFormSchema.properties,
    )[0]
    const isRequired = dynamicFormSchema.required?.includes(currentFieldKey)

    if (currentFieldKey in formData) {
      const value = formData[currentFieldKey]
      const detailValue = formData[currentFieldKey + '_detail']

      if (isRequired) {
        if (
          value === '' ||
          value === null ||
          value === undefined ||
          value.length == 0
        ) {
          setIsFieldValid(false)
        } else if (
          currentFieldValue?.properties?.radioOption &&
          (currentFieldValue?.properties?.radioOption?.description ===
            'positive' ||
            currentFieldValue?.properties?.radioOption?.description ===
              'negative')
        ) {
          if (
            currentFieldValue?.properties?.radioOption?.description ===
              'positive' &&
            value === true &&
            (detailValue === '' ||
              detailValue === null ||
              detailValue === undefined)
          ) {
            setIsFieldValid(false)
          } else if (
            currentFieldValue?.properties?.radioOption?.description ===
              'negative' &&
            value === false &&
            (detailValue === '' ||
              detailValue === null ||
              detailValue === undefined)
          ) {
            setIsFieldValid(false)
          } else {
            setIsFieldValid(true)
          }
        } else {
          setIsFieldValid(true)
        }
      } else {
        setIsFieldValid(true)
      }
    } else {
      setIsFieldValid(true)
    }
  }

  // Add templated form check and submit assessment
  const addTemplatedFormCheck = (jsonData: string) => {
    setIsAssessmentRunning(false)
    if (
      candidateId &&
      assessmentId &&
      initialAssessment?.assessmentResponseId
    ) {
      submitAssessment({
        candidateId: candidateId,
        assessmentId: assessmentId,
        jsonData,
        assessmentResponseId: initialAssessment.assessmentResponseId,
        examCompletionTime: parseFloat(formattedTime?.replace(/:/g, '.') ?? 0),
        mouseOutOfScreen: parseFloat(
          formatTime(timeOutside)?.replace(/:/g, '.') ?? 0,
        ),
      }).then(() => {
        // navigate(
        //   `/candidate/${candidateId}/assessment/${assessmentId}/thankyou`,
        //   {
        //     state: {
        //       title: 'Thank you!',
        //       description: 'You have now completed the assessment',
        //       hasBottomCards: true,
        //     },
        //   },
        // )
        verifyAddons()
      })
    }
  }

  // Stop timer on assessment saving
  useEffect(() => {
    if (isSavingAssessment && timerRef.current) {
      timerRef.current.handleStop() // Trigger handleCancel on the Timer component
      setAddonSubmitOnExit(false)
    }
  }, [isSavingAssessment])

  // Update screen state for full screen
  useEffect(() => {
    if (showFullscreenWarningModal) {
      setIsOutOfTheFullScreen(true)
    }
  }, [showFullscreenWarningModal])

  // Full screen warning modal button actions
  const primaryBtnAction = () => {
    setIsOutOfTheFullScreen(false)
    enterFullscreen()
  }

  const secondaryBtnAction = () => {
    setIsOutOfTheFullScreen(false)
    if (
      initialAssessment?.assessmentName !== assessmentTypes.TYPING_TEST &&
      initialAssessment?.assessmentName !== assessmentTypes.PERSONALITY_TEST
    ) {
      addTemplatedFormCheck(JSON.stringify(formData))
    } else {
      setAddonSubmitOnExit(true)
    }
  }

  return (
    <>
      <div
        className={`${className} min-h-screen flex flex-col overflow-y-hidden relative`}>
        {isLoadingDynamicForm && <PageLoader size='xxs' />}
        {isAssessmentRunning ? (
          <div
            ref={disableCopyPasteRef}
            className='flex-grow mx-auto w-[80%] my-8'>
            <div className='w-full flex gap-3'>
              {initialAssessment?.assessmentName !==
                assessmentTypes.TYPING_TEST && (
                <>
                  <ProgressBar
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                  />
                  <Timer
                    ref={timerRef}
                    duration={parseInt(initialAssessment?.duration ?? '0') * 60}
                    allowedImages={maxSnaps}
                    onCapture={captureImage}
                    setFormattedTime={setFormattedTime}
                    isSavingAssessment={isSavingAssessment}
                    handleAssessmentDecline={handleAssessmentDecline}
                    isLoadingDeclineAssessment={isLoadingDeclineAssessment}
                  />
                </>
              )}
            </div>

            <div className='flex items-center justify-center'>
              {dynamicForm?.secondaryUrl && (
                <>
                  {isLogoLoading && (
                    <div className='flex items-center justify-center w-[130px] h-[42px]'>
                      <div className='w-12 h-12 border-4 border-Blue-500 border-t-transparent border-solid rounded-full animate-pulse200'></div>
                    </div>
                  )}
                  <img
                    className={`w-[130px] h-[42px] ${
                      isLogoLoading ? 'hidden' : 'block'
                    }`}
                    src={dynamicForm?.secondaryUrl}
                    alt='agency logo'
                    onLoad={() => setIsLogoLoading(false)}
                  />
                </>
              )}
            </div>

            {/* Regular assessment */}
            {dynamicFormSchema &&
              dynamicForm &&
              initialAssessment?.assessmentName !==
                assessmentTypes.TYPING_TEST &&
              initialAssessment?.assessmentName !==
                assessmentTypes.PERSONALITY_TEST && (
                <>
                  <div
                    ref={componentRef}
                    className='p-4 my-5 bg-white rounded-lg max-h-[70vh] overflow-y-auto styled-scroll'>
                    <FormBuilder
                      key={formKey}
                      ref={dynamicFormRef}
                      uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
                      schema={modifyParagraphOrHeadingSchema(dynamicFormSchema)}
                      formData={formData}
                      onChange={handleChange}
                      onError={(data) => {
                        if (data.length > 0) {
                          Toast({
                            alertHeader: 'One or more fields are required.',
                            status: 'Warning',
                          })
                        }
                      }}
                      onSubmit={(data) => {
                        addTemplatedFormCheck(JSON.stringify(data?.formData))
                      }}
                    />
                  </div>
                  <div className='flex h-10 gap-2 justify-start'>
                    {!isLastStep && (
                      <Button
                        onClick={() =>
                          setCurrentStep((prevStep) => prevStep + 1)
                        }
                        isDisabled={!isFieldValid}>
                        Next
                      </Button>
                    )}

                    {isLastStep && (
                      <Button
                        onClick={() => dynamicFormRef.current?.submit()}
                        isDisabled={isSavingAssessment || !isFieldValid}
                        isLoading={isSavingAssessment}>
                        Submit Answers
                      </Button>
                    )}
                  </div>
                </>
              )}

            {/* Typing test addon */}
            {dynamicFormSchema &&
              dynamicForm &&
              initialAssessment?.assessmentName ===
                assessmentTypes.TYPING_TEST && (
                <TypeTestAddon
                  duration={parseInt(initialAssessment?.duration ?? '0') * 60}
                  allowedImages={maxSnaps}
                  addonSubmitOnExit={addonSubmitOnExit}
                  onCapture={captureImage}
                  setFormattedTime={setFormattedTime}
                  isSavingAssessment={isSavingAssessment}
                  handleAssessmentDecline={handleAssessmentDecline}
                  isLoadingDeclineAssessment={isLoadingDeclineAssessment}
                  addTemplatedFormCheck={addTemplatedFormCheck}
                />
              )}

            {/* Personality test addon */}
            {dynamicFormSchema &&
              dynamicForm &&
              initialAssessment?.assessmentName ===
                assessmentTypes.PERSONALITY_TEST && (
                <PersonalityTestAddon
                  dynamicForm={dynamicForm}
                  currentStep={currentStep}
                  addonSubmitOnExit={addonSubmitOnExit}
                  addTemplatedFormCheck={addTemplatedFormCheck}
                  setCurrentStep={setCurrentStep}
                  setTotalSteps={setTotalSteps}
                  totalSteps={totalSteps}
                  isSavingAssessment={isSavingAssessment}
                />
              )}
          </div>
        ) : (
          <PageLoader size='xxs' />
        )}
        {dynamicFormSchema &&
          dynamicForm &&
          initialAssessment?.assessmentName !== assessmentTypes.TYPING_TEST &&
          initialAssessment?.assessmentName !==
            assessmentTypes.PERSONALITY_TEST && (
            <div className='fixed bottom-0 left-0 bg-white w-full flex items-center justify-between px-10 py-2'>
              <div className='flex-col justify-center items-center px-4 pl-[10%]'>
                <span className='text-[#6B7280] text-extra-small font-light'>
                  Powered by
                </span>
                <img className='p-[6px]' src={RefHubLogo} alt='Logo' />
              </div>
            </div>
          )}
      </div>

      {showFullscreenWarningModal && (
        <ExitFullScreenModal
          showModal={showFullscreenWarningModal}
          secondaryAction={secondaryBtnAction}
          primaryAction={primaryBtnAction}
          closeButtonAction={primaryBtnAction}
          secondaryBtnName='Leave full screen'
          primaryBtnName='Stay on full screen'
          isSavingAssessment={isSavingAssessment}
        />
      )}
    </>
  )
}

export default Questions
