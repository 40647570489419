import { MutableRefObject, useEffect, useRef } from 'react'

export const useDisableCopyPaste =
  (): MutableRefObject<HTMLDivElement | null> => {
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const handleCopyPaste = (e: ClipboardEvent) => {
        e.preventDefault()
      }

      const element = ref.current
      if (element) {
        element.addEventListener('copy', handleCopyPaste)
        element.addEventListener('paste', handleCopyPaste)
      }

      return () => {
        if (element) {
          element.removeEventListener('copy', handleCopyPaste)
          element.removeEventListener('paste', handleCopyPaste)
        }
      }
    }, [])

    return ref
  }
