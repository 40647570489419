import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Radio } from '@labourhub/labour-hub-ds'
import RefHubLogo from 'assets/images/refHubLogo.svg'
import { DynamicAssessmentFormType } from 'framework/api/methods/assessment/useGetDynamicAssessmentForm'

type DynamicFormDataTypes = {
  qId: number
  id: string
  question: string
}

interface PersonalityTestAddonProps {
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  totalSteps: number
  setTotalSteps: Dispatch<SetStateAction<number>>
  isSavingAssessment: boolean
  addTemplatedFormCheck: (jsonData: string) => void
  dynamicForm: DynamicAssessmentFormType
  addonSubmitOnExit: boolean
}

const PersonalityTestAddon: React.FC<PersonalityTestAddonProps> = ({
  currentStep,
  setCurrentStep,
  totalSteps,
  setTotalSteps,
  isSavingAssessment,
  addTemplatedFormCheck,
  dynamicForm,
  addonSubmitOnExit,
}) => {
  const [questionStep, setQuestionStep] = useState(0)
  const [resultData, setResultData] = useState<{ [key: string]: string }>({})
  const [isAllFieldsMarked, setIsAllFieldsMarked] = useState(false)
  const [dynamicFormData, setDynamicFormData] = useState<
    DynamicFormDataTypes[]
  >([])
  const isLastStep = currentStep === totalSteps

  // Parse dynamic form data on mount
  useEffect(() => {
    const jsonData = JSON.parse(dynamicForm.jsonSchema)
    jsonData.required.map((id, i) => {
      const question = jsonData.properties[id].title
      setDynamicFormData((prev) =>
        prev
          ? [...prev, { qId: i + 1, id, question }]
          : [{ qId: i + 1, id, question }],
      )
    })
  }, [dynamicForm])

  // Handle radio button selection
  const handleCheckedAnswers = (id: string, choice: string) => {
    setResultData((prev) => ({ ...prev, [id]: choice }))
  }

  // Update all fields marked state
  useEffect(() => {
    Object.keys(resultData).length === questionStep + (currentStep > 0 ? 7 : 5)
      ? setIsAllFieldsMarked(true)
      : setIsAllFieldsMarked(false)

    // resultData.length === dynamicForm.questions.length
    Object.keys(resultData).length === dynamicFormData?.length
      ? setIsAllFieldsMarked(true)
      : null
  }, [Object.keys(resultData).length, dynamicFormData?.length])

  // Update total steps based on dynamic form data
  useEffect(() => {
    const noOfQuestions = dynamicFormData?.length || 0
    const numOfSteps = Math.floor((noOfQuestions - 5) / 7 + 1)
    setTotalSteps(numOfSteps)
  }, [dynamicFormData])

  // Handle form submission
  const handleSubmit = () => {
    const formData = JSON.stringify(resultData)
    addTemplatedFormCheck(formData)
  }

  useEffect(() => {
    if (addonSubmitOnExit) {
      handleSubmit()
    }
  }, [addonSubmitOnExit])
  return (
    <>
      <div className='w-full relative h-[660px] bg-white shadow-md rounded-2xl px-[30px] py-6'>
        {/* Instructions for the first step */}
        {currentStep === 0 && (
          <p className='text-base font-Medium px-5 pb-5'>
            Describe yourself as you generally are now, not as you wish to be in
            the future. Describe yourself as you honestly see yourself, in
            relation to other people you know of the same sex as you are, and
            roughly your same age. So that you can describe yourself in an
            honest manner, your responses will be kept in absolute confidence.
            Indicate for each statement whether it is 1. Very Inaccurate, 2.
            Moderately Inaccurate, 3. Neither Accurate Nor Inaccurate, 4.
            Moderately Accurate, or 5. Very Accurate as a description of you.
          </p>
        )}

        {/* Dynamic form for personality test */}
        <form onSubmit={(e) => e.preventDefault()}>
          <table className='table-fixed w-full full relative'>
            {/* Table header */}
            <thead>
              <tr className='border-collapse border border-Gray-200'>
                <th className='border-collapse w-[40%] border border-Gray-200 text-start py-4 px-4 text-wrap text-[18px] leading-7 font-SemiBold text-[#105DA4]'>
                  How Accurately Can You Describe Yourself?
                </th>
                <th className='border-collapse border w-[12%] border-Gray-200 py-4 px-4 text-wrap text-[16px] leading-7 font-SemiBold'>
                  {' '}
                  Very Inaccurate
                </th>
                <th className='border-collapse border w-[12%] border-Gray-200 py-4 px-4 text-wrap text-[16px] leading-7 font-SemiBold'>
                  {' '}
                  Moderately Inaccurate
                </th>
                <th className='border-collapse border w-[12%] border-Gray-200 py-4 px-4 text-wrap text-[16px] leading-7 font-SemiBold'>
                  {' '}
                  Neither Accurate Nor Inaccurate
                </th>
                <th className='border-collapse border w-[12%] border-Gray-200 py-4 px-4 text-wrap text-[16px] leading-7 font-SemiBold'>
                  {' '}
                  Moderately Accurate
                </th>
                <th className='border-collapse border w-[12%] border-Gray-200 py-4 px-4 text-wrap text-[16px] leading-7 font-SemiBold'>
                  {' '}
                  Very Accurate
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className='border-collapse border border-Gray-200'>
              {Array.from({ length: currentStep === 0 ? 5 : 7 }, (_, i) => {
                if (dynamicFormData[i + questionStep]?.id) {
                  return (
                    <tr key={`tr-${i}${currentStep}`} className='h-[65px]'>
                      <td className='pl-4 border-collapse border-r border-Gray-200 text-[16px] leading-7 font-SemiBold'>
                        {dynamicFormData[i + questionStep]?.qId}
                        .&nbsp;
                        {dynamicFormData[i + questionStep]?.question}
                      </td>

                      {/* Radio buttons for each question */}
                      {Array.from({ length: 5 }, (_, j) => (
                        <td
                          key={j}
                          className='border-collapse border-r border-Gray-200'>
                          <Radio
                            key={`input-${i}${j}${currentStep}`}
                            labelText=''
                            helperText=''
                            required
                            value={j + 1}
                            className='w-full mx-auto items-center justify-center'
                            name={dynamicFormData[questionStep + i]?.id}
                            onChange={(e) =>
                              handleCheckedAnswers(
                                e.target.name,
                                e.target.value,
                              )
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
        </form>

        {!isLastStep && (
          <Button
            onClick={() => {
              setQuestionStep((prev) =>
                currentStep === 0 ? prev + 5 : prev + 7,
              )
              setCurrentStep((prevStep) => prevStep + 1)
              setIsAllFieldsMarked(false)
            }}
            className='mt-3'
            isDisabled={!isAllFieldsMarked}>
            Next
          </Button>
        )}

        {/* Submit button for final step */}
        {isLastStep && (
          <Button
            type='submit'
            className='mt-3'
            onClick={() => handleSubmit()}
            isDisabled={isSavingAssessment || !isAllFieldsMarked}
            isLoading={isSavingAssessment}>
            Submit Answers
          </Button>
        )}
      </div>
      <div className='fixed bottom-0 left-0 bg-white w-full flex items-center justify-between px-10 py-2 flex-row'>
        <div className='flex-col justify-center items-center px-4 pl-[12%]'>
          <span className='text-[#6B7280] text-extra-small font-light'>
            Powered by
          </span>
          <img className='p-[6px]' src={RefHubLogo} alt='Logo' />
        </div>
      </div>
    </>
  )
}

export default PersonalityTestAddon
