import { Modal } from 'components/molecules/Modal'

import '../modal/modal.scss'

interface ExitFullScreenModalProps {
  showModal: boolean
  primaryAction: () => void
  secondaryAction: () => void
  closeButtonAction: () => void
  primaryBtnName: string
  secondaryBtnName: string
  isSavingAssessment: boolean
}

const modalStyles = {
  primaryButton: { width: '100%', marginRight: '12px' },
  secondaryButton: { width: '100%', color: '#1F2937' },
  footer: {
    justifyContent: 'end',
    paddingLeft: '23px',
    paddingRight: '23px',
    flexDirection: 'row-reverse',
  },
}

const ExitFullScreenModal = ({
  showModal,
  primaryAction,
  secondaryAction,
  closeButtonAction,
  primaryBtnName,
  secondaryBtnName,
  isSavingAssessment,
}: ExitFullScreenModalProps) => {
  return (
    <Modal
      isActive={showModal}
      headerTitle=''
      className='!z-[150]'
      onClickPrimaryBtn={primaryAction}
      onClickSecondaryBtn={secondaryAction}
      //   onOverlayClick={secondaryAction}
      primaryButtonProps={{
        style: modalStyles.primaryButton,
      }}
      secondaryButtonProps={{
        style: modalStyles.secondaryButton,
      }}
      secondaryButtonLoading={isSavingAssessment}
      secondaryButtonDisable={isSavingAssessment}
      primaryButtonDisable={isSavingAssessment}
      onHeaderCloseButtonClick={() => {
        !isSavingAssessment && closeButtonAction()
      }}
      modalProps={{
        className: 'w-[700px] md:w-[600px] bg-white',
      }}
      footerProps={{
        style: modalStyles.footer,
      }}
      primaryButtonTitle={primaryBtnName}
      secondaryButtonTitle={secondaryBtnName}>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-[#FFF6EC]'>
          <i className='ri-error-warning-line text-[#DD7F0B] text-heading-3' />
        </div>
        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Fullscreen mode warning
        </span>
        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          To prevent potential cheating, you are not allowed to exit this
          window. Doing so will notify the assessment body and may result in a
          voided test. Please stay within the assessment window until the
          completion of your assessment.
        </span>
      </div>
    </Modal>
  )
}

export default ExitFullScreenModal
