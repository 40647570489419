import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

// Define the response interfaces for better type safety
export interface GetInitialAssessmentByIdResponse {
  listAssessmentResponse: ListAssessmentResponse[]
  message: string
  messageTitle: string | null
  //   isWebCamMandatory: boolean
  //   duration: any
  privacyPolicyUrl: string | null
}

export interface ListAssessmentResponse {
  assessmentId: string
  assessmentName: string
  assessmentResponseId: string
  duration: any
  isAddOn: boolean
  isAssessmentCompleted: boolean
  isContainsVideoTypeQuestions: boolean
  isWebCamMandatory: boolean
}

// Define a reusable function to fetch initial assessment data
const fetchInitialAssessmentDetailsById = async (
  id: string,
): Promise<GetInitialAssessmentByIdResponse> => {
  const data = await get(`/assessment/assessmentresponse/${id}/initialize`)
  return data?.data as GetInitialAssessmentByIdResponse
}

// Create a custom hook to handle the query logic
export const useGetInitialAssessmentDetailsById = (
  id: string | undefined | null,
  onSuccess?: (data: GetInitialAssessmentByIdResponse) => void,
) => {
  // Use useQuery with the custom fetch function and handle errors
  return useQuery(
    ['GET_INITIAL_ASSESSMENT_BY_ID', id],
    () => fetchInitialAssessmentDetailsById(id as string),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching initial assessment data',
          status: 'Error',
        })
      },
      onSuccess,
    },
  )
}
