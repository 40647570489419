import store from 'store'
import {
  appendTypedHistory,
  backtrackWord,
  setChar,
} from 'store/reducers/typeTest/wordSlice'

export const recordTest = (key: string) => {
  const { dispatch, getState } = store
  const {
    word: { typedWord, currWord, activeWordRefId },
  } = getState()

  const currWordEl = document.getElementById(activeWordRefId!)
  currWordEl?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  switch (key) {
    case ' ':
      //Until space bar is pressed no words are registerd
      if (typedWord === '') return
      currWordEl?.classList.add(typedWord !== currWord ? 'wrong' : 'right')
      dispatch(appendTypedHistory())
      break
    case 'Backspace':
      handleBackspace()
      break
    default:
      dispatch(setChar(typedWord + key))
      break
  }
}

const handleBackspace = () => {
  const { dispatch, getState } = store
  const {
    word: { typedWord, activeWordRefId, typedHistory },
  } = getState()

  const currIdx = typedHistory.length - 1
  const currWordEl = document.getElementById(activeWordRefId!)

  if (typedWord === '' && currIdx >= 0) {
    dispatch(backtrackWord())
  } else {
    const newTypedWord = typedWord.slice(0, typedWord.length - 1)
    const currTextEl = currWordEl?.childNodes[
      typedWord.length
    ] as HTMLDivElement

    currTextEl?.classList?.remove('right')
    currTextEl?.classList?.remove('wrong')

    dispatch(setChar(newTypedWord))
  }
}
