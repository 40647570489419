// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, ForwardedRef, forwardRef } from 'react'
import JSONForm from '@rjsf/core'
import validator from '@rjsf/validator-ajv6'

import { Rate } from 'components/atoms'
import IFrameVideo from 'components/atoms/IFrameVideo/IFrameVideo'
import ImageViewer from 'components/atoms/ImageViewer/ImageViewer'

import CustomTemplate from './CustomTemplate'

import './theme.scss'

export interface FormBuilderProps {
  schema: any
  formData?: any
  uiSchema: any
  buttonRef?: React.MutableRefObject<JSONForm<any, any> | null>
  onChange?: (event: any) => void
  onSubmit?: (event: any) => void
  onError?: (event: any) => void
  readonly?: boolean
  className?: string
  [x: string]: any
}

export const FormBuilder: FC<FormBuilderProps> = forwardRef(
  (
    {
      schema,
      formData,
      uiSchema,
      onChange,
      onSubmit,
      onError,
      readonly,
      className,
    }: FormBuilderProps,
    ref: ForwardedRef<JSONForm<any, any>>,
  ) => {
    const Rating = (props: any) => {
      const imageUrl = props.schema?.properties?.imageurl?.description || ''
      const videoUrl = props.schema?.properties?.videourl?.description || ''
      return (
        <div className='w-full flex gap-3 items-center' id='appendinContainer'>
          {imageUrl && <ImageViewer imageUrl={imageUrl} />}
          {videoUrl && <IFrameVideo link={videoUrl} />}
          <div
            className={`${
              imageUrl || videoUrl ? 'w-1/2' : 'w-full'
            } append-class-container`}>
            <Rate
              count={props.schema.enum.length}
              rating={props.value ? props.value : undefined}
              onRating={(e) => {
                props.onChange(e.toString())
              }}
              id={props.id}
            />
          </div>
        </div>
      )
    }

    const widgets = {
      rating: Rating,
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        const target = event.target as HTMLElement
        const targetType = target.getAttribute('type')
        const isTextInput = targetType === 'text'

        if (isTextInput) {
          event.preventDefault()
        }
      }
    }

    return (
      <div onKeyDown={handleKeyDown}>
        <JSONForm
          showErrorList={false}
          widgets={widgets}
          className={`jason-theme ${className}`}
          schema={schema}
          formData={formData}
          uiSchema={uiSchema}
          onSubmit={onSubmit}
          onChange={onChange}
          onError={onError}
          validator={validator}
          children={true}
          ref={ref}
          readonly={readonly}
          templates={{ FieldHelpTemplate: CustomTemplate }}
        />
      </div>
    )
  },
)

export default FormBuilder
