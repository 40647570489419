/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import RefHubLogo from 'assets/images/refHubLogo.svg'
import CN from 'classnames'
import { AgencyProfile } from 'framework/api/methods'

import { PageLoader } from 'components/atoms'
import { removeHttpsOrHttp } from 'utils'

import { Footer } from '../Footer'

export interface LeftMainContentProps {
  [x: string]: any
  isLoading: boolean
  agencyData: AgencyProfile | undefined
}

export const LeftMainContent: FC<LeftMainContentProps> = ({
  className,
  isLoading,
  agencyData,
  ...restProps
}: LeftMainContentProps) => {
  const LeftMainContentClasses = CN(
    `left-main-content md:mr-5 w-full md:w-[320px] relative`,
    className,
  )

  const [isExpand, setIsExpand] = React.useState(false)

  return (
    <div className={LeftMainContentClasses} {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      <div
        className={CN('bg-white rounded-[8px] px-4 md:px-5 md:py-8', {
          'py-3': !isExpand,
          'py-5': isExpand,
        })}>
        <div className='flex justify-between items-center'>
          <div className='flex items-center md:mb-8'>
            {agencyData?.secondaryLogoUrl ? (
              <img
                className='md:h-[42px] h-[32px]'
                src={agencyData?.secondaryLogoUrl}
                alt='agency logo'
              />
            ) : (
              <span className='text-[20px] font-Bold text-[#6C6D6F] leading-6 break-all'>
                {agencyData?.name}
              </span>
            )}
          </div>
          <div
            className='flex justify-center items-center w-[34px] h-[34px] rounded-[50%] bg-Cobalt-50 md:hidden'
            onClick={() => {
              setIsExpand(!isExpand)
            }}>
            <i
              className={CN('text-[24px] text-Gray-800', {
                'ri-arrow-down-s-line': !isExpand,
                'ri-arrow-up-s-line': isExpand,
              })}
            />
          </div>
        </div>
        <div
          className={CN('md:block md:mt-0', {
            'block mt-5': isExpand,
            'hidden': !isExpand,
          })}>
          {agencyData?.phone && (
            <div className='flex items-center mb-6'>
              <div className='w-[36px] h-[36px] flex items-center justify-center p-[10px] border border-Gray-300 rounded-lg'>
                <i
                  className='ri-phone-line text-[15px] text-Cobalt-600'
                  data-tip='Phone'
                  data-for='Phone'
                />
              </div>
              <span className='text-Gray-800 font-Medium text-[14px] leading-5 ml-4 break-all'>
                {agencyData?.phone}
              </span>
            </div>
          )}

          {agencyData?.website && (
            <div className='flex items-center mb-6'>
              <div className='w-[36px] h-[36px] flex items-center justify-center p-[10px] border border-Gray-300 rounded-lg'>
                <i
                  className='ri-global-line text-[15px] text-Cobalt-600'
                  data-tip='Website'
                  data-for='Website'
                />
              </div>
              <a
                href={agencyData?.website}
                target='_blank'
                className='text-Cobalt-600 font-Medium focus:outline-none text-[14px] leading-5 ml-4 break-all'
                data-for='WebSite'>
                {removeHttpsOrHttp(agencyData?.website)}
              </a>
            </div>
          )}
          {agencyData?.address && agencyData?.address.country && (
            <div className='flex items-center'>
              <div className='w-[36px] h-[36px] flex items-center justify-center p-[10px] border border-Gray-300 rounded-lg'>
                <i
                  className='ri-map-pin-2-line text-[15px] text-Cobalt-600'
                  data-tip='Phone'
                  data-for='Phone'
                />
              </div>
              <span className='text-Gray-800 font-Medium text-[14px] leading-5 ml-4 max-w-[188px] break-all'>
                {agencyData?.address?.lineOne ? (
                  <>
                    {agencyData?.address?.lineOne}, <br />
                  </>
                ) : (
                  ''
                )}
                {/* {agencyData?.address?.lineTwo &&
              agencyData?.address?.lineTwo !== ''
                ? `${agencyData?.address?.lineTwo}, `
                : ''} */}
                {agencyData?.address?.city
                  ? `${agencyData?.address?.city}, `
                  : ''}
                {agencyData?.address?.state ? (
                  <>
                    {agencyData?.address?.state}, <br />
                  </>
                ) : (
                  ''
                )}
                {agencyData?.address?.postCode
                  ? `${agencyData?.address?.postCode}, `
                  : ''}
                {agencyData?.address?.country
                  ? `${agencyData?.address?.country}`
                  : ''}
              </span>
            </div>
          )}
          <div
            onClick={() => {
              window.open(
                'https://www.refhub.com.au/',
                '_blank',
                'noopener,noreferrer',
              )
            }}
            className='bg-white rounded-[8px] px-3 py-3 w-full md:w-[320px] flex md:hidden
                        border border-Gray-300 mt-8 flex-col justify-center items-center cursor-pointer'>
            <span className='text-[#6B7280] text-extra-small font-light'>
              Powered by
            </span>
            <img className='p-[6px]' src={RefHubLogo} alt='Logo' />
          </div>
          <div className='md:hidden block'>
            <Footer />
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          window.open(
            'https://www.refhub.com.au/',
            '_blank',
            'noopener,noreferrer',
          )
        }}
        className='bg-white rounded-[8px] px-3 py-3 w-full md:w-[320px] hidden md:flex 
      border border-Gray-300 mt-5 flex-col justify-center items-center cursor-pointer'>
        <span className='text-[#6B7280] text-extra-small font-light'>
          Powered by
        </span>
        <img className='p-[6px]' src={RefHubLogo} alt='Logo' />
      </div>
      <div className='hidden md:block'>
        <Footer />
      </div>
    </div>
  )
}

LeftMainContent.defaultProps = {}

export default LeftMainContent
