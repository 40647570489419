import { useCallback, useEffect, useState } from 'react'

interface WebcamPermissionHook {
  hasWebcamPermission: boolean
  stopWebcam: () => void
  mediaStream: MediaStream | null
}

export const useWebcamPermission = (): WebcamPermissionHook => {
  const [hasWebcamPermission, setHasWebcamPermission] = useState<boolean>(false)
  //   const [isWebcamOn, setIsWebcamOn] = useState(false)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)

  const checkPermission = async (): Promise<boolean> => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      })
      return permissionStatus.state === 'granted'
    } catch (error) {
      console.error('Error checking webcam permission:', error)
      return false
    }
  }

  const checkWebcamPermission = useCallback(async () => {
    try {
      const permissionGranted = await checkPermission()
      setHasWebcamPermission(permissionGranted)

      // Do not start the webcam here
      //   if (permissionGranted) {
      //     try {
      //       // Attempt to get a media stream to check if the webcam is already on
      //       const stream = await navigator.mediaDevices.getUserMedia({
      //         video: true,
      //       })
      //       setMediaStream(stream)
      //       setIsWebcamOn(stream.active)
      //     } catch (err) {
      //       setIsWebcamOn(false)
      //     }
      //   } else {
      //     setIsWebcamOn(false)
      //   }
    } catch (error) {
      console.error('Error in checkWebcamPermission:', error)
      setHasWebcamPermission(false)
      //   setIsWebcamOn(false)
    }
  }, [])

  //   const startWebcam = async () => {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         video: true,
  //       })
  //       setMediaStream(stream)
  //       setIsWebcamOn(true)
  //     } catch (err) {
  //       console.error('Error accessing webcam:', err)
  //       setIsWebcamOn(false)
  //     }
  //   }

  // const stopWebcam = () => {
  //   if (mediaStream) {
  //     mediaStream.getTracks().forEach((track) => track.stop())
  //     //   setIsWebcamOn(false)
  //     setMediaStream(null)
  //   }
  // }
  const stopWebcam = useCallback(() => {
    if (!mediaStream) {
      console.log('No media stream found')
      return
    }

    try {
      mediaStream.getTracks().forEach((track) => {
        track.stop()
      })
      setMediaStream(null)
    } catch (error) {
      console.error('Error stopping webcam:', error)
    }
  }, [mediaStream])

  // Effect to check permission on mount and clean up on unmount
  useEffect(() => {
    const initializeWebcam = async () => {
      await checkWebcamPermission()
    }

    initializeWebcam()

    // Cleanup function
    return () => {
      stopWebcam()
    }
  }, [checkWebcamPermission, hasWebcamPermission])

  // Add permission change listener
  useEffect(() => {
    const handlePermissionChange = () => {
      checkWebcamPermission()
    }

    // Listen for permission changes
    navigator.permissions
      .query({ name: 'camera' as PermissionName })
      .then((permissionStatus) => {
        permissionStatus.addEventListener('change', handlePermissionChange)
      })
      .catch((error) => {
        console.error('Error setting up permission listener:', error)
      })

    // Cleanup listener
    return () => {
      navigator.permissions
        .query({ name: 'camera' as PermissionName })
        .then((permissionStatus) => {
          permissionStatus.removeEventListener('change', handlePermissionChange)
        })
        .catch((error) => {
          console.error('Error cleaning up permission listener:', error)
        })
    }
  }, [checkWebcamPermission])

  return {
    hasWebcamPermission,
    stopWebcam,
    mediaStream,
  }
}
