import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

export interface ImageUploadParams {
  AId: string
  CId: string
  AssessmentScreenCaptureImage: File
}

export const useImageUpload = () => {
  return useMutation(
    ({ AId, CId, AssessmentScreenCaptureImage }: ImageUploadParams) => {
      const bodyFormData = new FormData()
      bodyFormData.append(
        'AssessmentScreenCaptureImage',
        AssessmentScreenCaptureImage,
      )

      return putAPIwithFormData(
        `/candidate/${CId}/assessment/${AId}/screencapture`,
        bodyFormData,
        {},
      )
    },
  )
}
