import { Modal } from '@labourhub/labour-hub-ds'
import { useDeclineCandidateAssessment } from 'framework/api/methods/assessment/declineCandidateAssessment'
import { ListAssessmentResponse } from 'framework/api/methods/assessment/useGetInitialAssessmentDetailsById'
import { ModalProps } from 'types'

import { assessmentTypes } from 'utils/assessmentTypes'

type KeyboardRequiredModal = ModalProps & {
  handleStartLater: () => void
  listAssessment: ListAssessmentResponse[]
  candidateId: string
  refetchInitialAssessment: () => void
}

const buttonStyles = {
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: '500',
}

const primaryButtonStyles = {
  ...buttonStyles,
  maxWidth: '106px',
  marginRight: '0px',
  marginLeft: '0px',
  paddingRight: '0px',
  paddingLeft: '0px',
}

const secondaryButtonStyles = {
  ...buttonStyles,
  minWidth: '156px',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  color: '#1F2937',
  flex: 'nowrap',
}

const modalStyles = {
  width: '550px',
  height: '300px',
}

const footerStyles = {
  justifyContent: 'flex-start',
  display: 'flex',
  flexDirection: 'row-reverse',
}

export default function KeyboardRequiredModal({
  isModalActive,
  setIsModalActive,
  handleStartLater,
  listAssessment,
  candidateId,
  refetchInitialAssessment,
}: KeyboardRequiredModal) {
  const { mutateAsync: declineAssessment } = useDeclineCandidateAssessment()

  const handleDeclineTypeTest = async () => {
    const typingTests = listAssessment.filter(
      (item) => item.assessmentName === assessmentTypes.TYPING_TEST,
    )

    try {
      // Decline Typing Test assessment
      await Promise.all(
        typingTests.map((test) =>
          declineAssessment({
            candidateId,
            assessmentId: test.assessmentId,
            assessmentResponseId: test.assessmentResponseId,
          }),
        ),
      )

      // Once Typing Test is declined, update session storage and close modal and refetch initial assessment
      setIsModalActive(false)
      sessionStorage.setItem('isInSession', 'true')
      sessionStorage.setItem('withoutTypingTest', 'true')
      refetchInitialAssessment()
    } catch (error) {
      console.error('Error declining typing test assessments:', error)
    }
  }

  return (
    <Modal
      isActive={isModalActive}
      onClickPrimaryBtn={handleStartLater}
      onClickSecondaryBtn={handleDeclineTypeTest}
      headerTitle={''}
      primaryButtonProps={{
        style: primaryButtonStyles,
      }}
      secondaryButtonProps={{
        style: secondaryButtonStyles,
      }}
      modalProps={{
        style: modalStyles,
      }}
      footerProps={{
        style: footerStyles,
      }}
      primaryButtonTitle='Start Later'
      secondaryButtonTitle='Decline to Answer'>
      <div className='flex  flex-col items-center justify-center px-6 '>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Yellow-50'>
          <i className='ri-error-warning-line text-Yellow-400 text-heading-3'></i>
        </div>

        <span className='pt-4 text-Gray-900 text-heading-5 font-Medium'>
          Full-size Keyboard Required
        </span>

        <span className='pt-2 pb-5 text-center text-Gray-500  text-small font-Regular'>
          This request includes a typing test that needs a full-size keyboard.
          Select{' '}
          <span className='text-[#3F3D56] font-SemiBold'>
            Decline to Answer{' '}
          </span>
          if you don’t have a full-size keyboard. This will decline the typing
          test and allow you to proceed with the other assessments. Select{' '}
          <span className='text-[#3F3D56] font-SemiBold'>Start Later </span>if
          you'd like to switch devices and return to the assessment when ready.
        </span>
      </div>
    </Modal>
  )
}
