/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetCandidateById } from 'framework/api/methods'
import { useDeclineCandidateAssessment } from 'framework/api/methods/assessment/declineCandidateAssessment'
import { useImageUpload } from 'framework/api/methods/assessment/imageUpload'
import {
  ListAssessmentResponse,
  useGetInitialAssessmentDetailsById,
} from 'framework/api/methods/assessment/useGetInitialAssessmentDetailsById'
import { useFullscreen, useWebcamPermission } from 'hooks'

import { PageLoader } from 'components/atoms'
import { MainLayout } from 'components/layouts/MainLayout'
import { assessmentTypes } from 'utils/assessmentTypes'

import KeyboardRequiredModal from './components/KeyboardRequiredModal'
import Questions from './Questions/Questions'
import InitialPage from './InitialPage'

type AssessmentDetailsProps = {
  imageSource?: string | undefined
  [x: string]: any
}

const MAX_SNAPS = 11

export const AssessmentDetails = ({
  className,
  ...restProps
}: AssessmentDetailsProps) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const isInSession = sessionStorage.getItem('isInSession')

  const { pathname: locationPath } = useLocation()
  const candidateId = locationPath.split('/')[2]
  const token = sessionStorage.getItem('token')
  const accessToken = searchParams.get('token')
  const assessmentResponseId = searchParams.get('assessmentResponseId')

  const { hasWebcamPermission, stopWebcam } = useWebcamPermission()
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
  const [isWebcamAccess, setIsWebcamAccess] = useState(false)
  const [assessmentId, setAssessmentId] = useState(locationPath.split('/')[4])
  const [currentStep, setCurrentStep] = useState(1)
  const [isAssessmentRunning, setIsAssessmentRunning] = useState(false)
  const [isKeyboardRequired, setIsKeyboardRequired] = useState(false)

  const { exitFullscreen } = useFullscreen()
  const [isContinueButtonClicked, setIsContinueButtonClicked] =
    useState<boolean>(false)
  const [listAssessment, setListAssessment] = useState<
    ListAssessmentResponse[]
  >([])
  const [currentAssessment, setCurrentAssessment] =
    useState<ListAssessmentResponse | null>(null)
  const [isTermsAndCondition, setIsTermsAndCondition] = useState(false)
  const { candidateProfile, agencyData } = useSelector(
    (state: any) => state.candidate,
  )

  const {
    refetch: refetchInitialAssessment,
    data: initialAssessment,
    isLoading: isLoadingInitialAssessment,
  } = useGetInitialAssessmentDetailsById(assessmentResponseId)

  useEffect(() => {
    if (token && token.length > 0) {
      refetchInitialAssessment()
    }
  }, [
    assessmentResponseId,
    assessmentId,
    token,
    agencyData,
    candidateProfile,
    // refetchInitialAssessment,
  ])

  useEffect(() => {
    const userAgent = navigator.userAgent
    // Check for mobile devices
    if (
      /android/i.test(userAgent) ||
      /iPad|iPhone|iPod/.test(userAgent) ||
      /windows phone/i.test(userAgent) ||
      /tablet|ipad|playbook|silk/i.test(userAgent)
    ) {
      const isTypingTestPresent =
        initialAssessment?.listAssessmentResponse.some(
          (item) => item.assessmentName === assessmentTypes.TYPING_TEST,
        )
      if (isTypingTestPresent) {
        setIsKeyboardRequired(true)
      }
    }
  }, [initialAssessment?.listAssessmentResponse?.length])

  useEffect(() => {
    if (initialAssessment?.listAssessmentResponse) {
      const sortedAssessments = [
        ...initialAssessment.listAssessmentResponse,
      ].sort((a, b) => {
        a.isAddOn === b.isAddOn ? 0 : a.isAddOn ? 1 : -1
        //put typing test at the end of the list
        if (a.assessmentName === assessmentTypes.TYPING_TEST) return 1
        if (b.assessmentName === assessmentTypes.TYPING_TEST) return -1
        return 0
      })
      //todo modify based on pending design changes
      const isTypingTest = sessionStorage.getItem('withoutTypingTest')
      if (isTypingTest) {
        const filteredAssessments = sortedAssessments.filter(
          (assessment) =>
            assessment.assessmentName != assessmentTypes.TYPING_TEST,
        )
        setListAssessment(filteredAssessments)
      } else {
        setListAssessment(sortedAssessments)
      }

      const currentIndex = sortedAssessments.findIndex(
        (assessment) => assessment.assessmentId === assessmentId,
      )
      setCurrentStep(currentIndex + 1)
    }
  }, [initialAssessment, assessmentId, candidateId])

  useEffect(() => {
    if (hasWebcamPermission) {
      Toast({
        alertHeader:
          'Webcam permission has been granted for our application. Your webcam will be activated once the assessment starts',
        status: 'Warning',
      })
    }
  }, [hasWebcamPermission])

  const verifyAddons = async () => {
    if (listAssessment.length === 0 || !assessmentId) return

    const current = listAssessment.find(
      (assessment) => assessment.assessmentId === assessmentId,
    )
    setCurrentAssessment(current || null)
    stopWebcam()

    if (current?.isAssessmentCompleted || !current) {
      const nextAssessment = listAssessment.find(
        (assessment) => !assessment.isAssessmentCompleted,
      )

      if (nextAssessment) {
        const newUrl = new URL(window.location.href)
        newUrl.pathname = `/candidate/${candidateId}/assessment/${nextAssessment.assessmentId}`
        newUrl.searchParams.set(
          'assessmentResponseId',
          assessmentResponseId ?? '',
        )
        newUrl.searchParams.set('token', accessToken || '')
        window.location.href = newUrl.toString()

        setAssessmentId(nextAssessment.assessmentId)
      } else {
        handleAllAssessmentsCompleted()
      }
    } else if (
      listAssessment.every((assessment) => assessment.isAssessmentCompleted)
    ) {
      handleAllAssessmentsCompleted()
    } else {
      await refetchInitialAssessment()
    }
  }

  const handleStartLater = () => {
    window.close()
  }

  const handleAllAssessmentsCompleted = () => {
    exitFullscreen()
    navigate(`/candidate/${candidateId}/assessment/${assessmentId}/thankyou`, {
      state: {
        title: 'Thank you!',
        description: 'You have now completed the assessment',
        hasBottomCards: true,
      },
    })
    window.location.reload()
  }

  useEffect(() => {
    verifyAddons()
  }, [listAssessment, assessmentId, candidateId])

  //   const EXAM_DURATION =
  //     parseFloat(initialAssessment?.duration || '0') * 60 * 1000
  //   const INTERVAL = EXAM_DURATION / MAX_SNAPS

  const [isOpenDeclineCheck, setIsOpenDeclineCheck] = useState(false)
  const [snaps, setSnaps] = useState<string[]>([])
  //   const [screenshots, setScreenshots] = useState<string[]>([])
  //   const [isCapturing, setIsCapturing] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  //   const {
  //     data: dynamicForm,
  //     isLoading: isLoadingDynamicForm,
  //     refetch: dynamicAssessmentRefetch,
  //   } = useGetDynamicAssessmentForm(assessmentId ?? '')

  //   const startCapture = () => {
  //     setIsCapturing(true)
  //     navigator.mediaDevices
  //       .getUserMedia({ video: true })
  //       .then((stream) => {
  //         if (videoRef.current) {
  //           videoRef.current.srcObject = stream
  //           videoRef.current.play()
  //         }
  //       })
  //       .catch((err) => console.error('Error accessing webcam: ', err))
  //   }

  const webCamAccess = async (isMandatory: boolean) => {
    // if (hasWebcamPermission) {
    //   setIsContinueButtonClicked(true)
    // } else {
    try {
      await navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream

            const playPromise = videoRef.current.play()
            if (playPromise !== undefined && playPromise !== null) {
              playPromise
                .then(() => {
                  setIsContinueButtonClicked(true)
                  setIsWebcamAccess(true)
                })
                .catch((error) => {
                  console.log(error.message)
                })
            } else {
              setIsContinueButtonClicked(!isMandatory)
              setIsWebcamAccess(false)
              isMandatory &&
                Toast({
                  alertHeader:
                    'Webcam is not activates. Please activate your webcam and try again',
                  status: 'Warning',
                })
            }
          }
        })
        .catch((err) => {
          setIsContinueButtonClicked(!isMandatory)
          setIsWebcamAccess(false)
          isMandatory &&
            Toast({
              alertHeader:
                'Webcam is blocked. Please reset your camera permission and try again',
              status: 'Error',
            })
          console.error('Error accessing webcam: ', err)
        })
    } catch (err) {
      setIsContinueButtonClicked(!isMandatory)
      setIsWebcamAccess(false)
      isMandatory &&
        Toast({
          alertHeader:
            'Webcam is blocked. Please reset your camera permission and try again',
          status: 'Error',
        })
      console.error('Error accessing the webcam: ', err)
    }
    // }
  }

  const handleContinue = async () => {
    // startWebcam()
    if (currentAssessment?.isWebCamMandatory) {
      //   if (hasWebcamPermission && isWebcamOn) {
      //     setIsContinueButtonClicked(true)
      //   } else {
      //     await webCamAccess(true)
      //   }
      await webCamAccess(true)
    } else {
      //   if (hasWebcamPermission && isWebcamOn) {
      //     setIsContinueButtonClicked(true)
      //   } else {
      //     await webCamAccess(false)
      //   }
      await webCamAccess(false)
      //   setIsContinueButtonClicked(true)
    }
    setIsAssessmentRunning(true)
  }

  const captureImage = () => {
    if (
      canvasRef.current &&
      videoRef.current &&
      snaps.length < MAX_SNAPS &&
      isWebcamAccess
    ) {
      const context = canvasRef.current.getContext('2d')
      if (context) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height,
        )
        // const image = canvasRef.current.toDataURL('image/png')
        // setSnaps((prevImages) => [...prevImages, image])
        // sendImageToBackend(image)
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], `screenshot_${Date.now()}.png`, {
              type: 'image/png',
            })
            setSnaps((prevScreenshots: any) => [...prevScreenshots, file])
            sendImageToBackend(file)
          }
        }, 'image/png')
        // captureScreenshot() // Take a screenshot after capturing the image
      }
    }
    //  else if (!isWebcamAccess) {
    //   captureScreenshot()
    // }
  }

  // screenshot feature hide
  // const captureScreenshot = () => {
  //   if (screenshots.length < 10) {
  //     const element = document.getElementById('root')
  //     if (!element) {
  //       return
  //     }
  //     html2canvas(element)
  //       .then((canvas) => {
  //         canvas.toBlob((blob) => {
  //           if (blob) {
  //             const file = new File([blob], `screenshot_${Date.now()}.png`, {
  //               type: 'image/png',
  //             })
  //             setScreenshots((prevScreenshots: any) => [
  //               ...prevScreenshots,
  //               file,
  //             ])
  //             sendImageToBackend(file)
  //           }
  //         }, 'image/png')
  //       })
  //       .catch((err) => console.error('Error capturing screenshot: ', err))
  //   }
  // }

  const { mutate: uploadImage } = useImageUpload()

  const sendImageToBackend = (image: File) => {
    uploadImage(
      {
        AId: assessmentId,
        CId: candidateId,
        AssessmentScreenCaptureImage: image,
      },
      {
        onError: () => {
          Toast({
            alertHeader: 'Something went wrong',
            alertBody: '',
            status: 'Error',
          })
        },
      },
    )
  }

  const steps: {
    number: number
    label: string
  }[] =
    listAssessment.map((assessment: ListAssessmentResponse, i: number) => ({
      number: i + 1,
      label: assessment.assessmentName,
    })) ?? []

  const { isLoading: isLoadingCandidateInfo } = useGetCandidateById(
    sessionStorage.getItem('candidateId'),
  )

  const AssessmentDetailsClasses = CN(
    `candidate-details mt-5 md:mt-0`,
    className,
  )

  const {
    mutateAsync: declineAssessment,
    isLoading: isLoadingDeclineAssessment,
  } = useDeclineCandidateAssessment()

  const handleAssessmentDecline = () => {
    if (candidateId && assessmentId && assessmentResponseId) {
      setIsAssessmentRunning(false)
      declineAssessment({
        candidateId: candidateId,
        assessmentId: assessmentId,
        assessmentResponseId: currentAssessment?.assessmentResponseId ?? '',
      })
        .then(() => {
          Toast({
            alertHeader: 'Assessment is declined successfully',
            status: 'Success',
          })
          stopWebcam()
          if (steps.length === currentStep) {
            navigate(
              `/candidate/${candidateId}/assessment/${assessmentId}/thankyou`,
              {
                state: {
                  title: 'Thank you!',
                  description: 'We will get back to you shortly',
                  hasBottomCards: true,
                },
              },
            )
          } else {
            verifyAddons()
          }
        })
        .finally(() => {
          setIsOpenDeclineCheck(false)
        })
    }
  }

  return (
    <>
      {(isLoadingCandidateInfo || isLoadingInitialAssessment) && (
        <PageLoader size='xxs' />
      )}
      <div className='overflow-hidden'>
        <video ref={videoRef} style={{ display: 'none' }} />
        <canvas
          ref={canvasRef}
          style={{ display: 'none' }}
          width='640'
          height='480'
        />
        {isContinueButtonClicked ? (
          <Questions
            initialAssessment={currentAssessment}
            maxSnaps={MAX_SNAPS}
            captureImage={captureImage}
            handleAssessmentDecline={handleAssessmentDecline}
            isLoadingDeclineAssessment={isLoadingDeclineAssessment}
            verifyAddons={verifyAddons}
            assessmentId={assessmentId}
            candidateId={candidateId}
            isAssessmentRunning={isAssessmentRunning}
            setIsAssessmentRunning={setIsAssessmentRunning}
          />
        ) : (
          <MainLayout hasLeftMainContent={true}>
            <InitialPage
              AssessmentDetailsClasses={AssessmentDetailsClasses}
              currentStep={currentStep}
              steps={steps}
              restProps={restProps}
              initialAssessment={currentAssessment}
              setShowDisclaimerModal={setShowDisclaimerModal}
              showDisclaimerModal={showDisclaimerModal}
              isOpenDeclineCheck={isOpenDeclineCheck}
              handleAssessmentDecline={handleAssessmentDecline}
              setIsOpenDeclineCheck={setIsOpenDeclineCheck}
              isLoadingDeclineAssessment={isLoadingDeclineAssessment}
              setIsTermsAndCondition={setIsTermsAndCondition}
              isTermsAndCondition={isTermsAndCondition}
              handleContinue={handleContinue}
              privacyPolicyUrl={initialAssessment?.privacyPolicyUrl}
            />
          </MainLayout>
        )}
      </div>

      {isKeyboardRequired && !isInSession && (
        <KeyboardRequiredModal
          isModalActive={isKeyboardRequired}
          setIsModalActive={setIsKeyboardRequired}
          listAssessment={listAssessment}
          candidateId={candidateId}
          refetchInitialAssessment={refetchInitialAssessment}
          handleStartLater={handleStartLater}
        />
      )}
    </>
  )
}
