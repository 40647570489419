import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from '@labourhub/labour-hub-ds'

import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'

export const PhoneRefCheckModal = () => {
  const { pathname: locationPath, search } = useLocation()
  const navigate = useNavigate()

  const candidateId = locationPath.split('/')[3]
  const refereeId = locationPath.split('/')[5]

  const [isModalActive, setIsModalActive] = useState(true)

  // Extract query parameters from URL
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')
  const isStaffRequest = searchParams.get('IsStaffRequest')
  const isCareerInterest = searchParams.get('IsCareerInterest')

  const queryString = `?token=${token}&IsStaffRequest=${isStaffRequest}&IsCareerInterest=${isCareerInterest}`

  const handleSend = () => {
    navigate(
      `/phonerefcheck/candidate/${candidateId}/referee/${refereeId}/confirmed${queryString}`,
    )
  }

  const handleClose = () => {
    navigate(`/candidate/${candidateId}/referee/${refereeId}${queryString}`)
  }

  return (
    <Modal
      isActive={isModalActive}
      headerTitle=''
      className='z-50'
      onHeaderCloseButtonClick={() => handleClose()}
      onClickSecondaryBtn={() => handleClose()}
      onOverlayClick={() => handleClose()}
      onClickPrimaryBtn={() => handleSend()}
      modalProps={{
        style: { width: '575px' },
      }}
      headerCloseButtonProps={{
        style: { fontSize: '24px' },
      }}
      primaryButtonTitle='Yes, I’m sure'
      secondaryButtonTitle='Cancel'
      footerProps={{
        style: {
          justifyContent: 'flex-start',
          backgroundColor: '#F3F4F6',
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonProps={{
        style: { maxWidth: '120px' },
      }}
      secondaryButtonProps={{
        style: { maxWidth: '81px' },
        textTheme: 'black',
      }}>
      {/* Content of RefereeFeedbackSurvey*/}
      <RowWithAlignment
        justifyAlignment='center'
        className='flex flex-col pb-10'>
        <Column className='flex items-center justify-center w-12 h-12 rounded-full bg-Yellow-50'>
          <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
        </Column>
        <Column className='text-center w-[527px]'>
          <Column className='mx-3 mt-6 space-y-3 text-base'>
            <span className='font-Medium text-heading-5 text-[#171923] mb-2'>
              Request Phone Reference Check
            </span>
          </Column>
          <span className='text-base font-Regular text-Gray-700'>
            This will notify the consultant that you would like to do a phone
            reference check
          </span>
        </Column>
      </RowWithAlignment>
    </Modal>
  )
}
