/** Users */
import { combineReducers } from 'redux'

import candidateReducer from './candidate/candidateSlice'
import layOutReducer from './layout/layoutSlice'
import wordReducer from './typeTest/wordSlice'
import userReducer from './user/userSlice'

const appReducer = combineReducers({
  user: userReducer,
  layout: layOutReducer,
  candidate: candidateReducer,
  word: wordReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

// Define RootState using ReturnType utility type
export type RootState = ReturnType<typeof appReducer>
