import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { cn } from 'utils'

import ReachedTimeModal from './ReachedTimeModal'

export type TimerProps = {
  duration: number
  typingTest?: boolean
  allowedImages: number
  onCapture: () => void
  setFormattedTime: React.Dispatch<React.SetStateAction<string>>
  isSavingAssessment: boolean
  handleAssessmentDecline: () => void
  isLoadingDeclineAssessment: boolean
  timerHasStarted?: boolean
}

export type TimerHandle = {
  handleStop: () => void
  time: number
  intervalRef: MutableRefObject<NodeJS.Timeout | null>
}

const Timer = forwardRef<TimerHandle, TimerProps>(
  (
    {
      duration,
      onCapture,
      allowedImages,
      setFormattedTime,
      isSavingAssessment,
      handleAssessmentDecline,
      isLoadingDeclineAssessment,
      typingTest,
      timerHasStarted = true,
    },
    ref,
  ) => {
    const [time, setTime] = useState<number>(duration)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [continueFromZero, setContinueFromZero] = useState<boolean>(false)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const captureIntervalRef = useRef<NodeJS.Timeout | null>(null)

    useImperativeHandle(ref, () => ({
      handleStop,
      time,
      intervalRef,
    }))

    // Start the timer when the component mounts
    useEffect(() => {
      if (timerHasStarted) {
        intervalRef.current = setInterval(() => {
          setTime((prevTime) => prevTime - 1)
        }, 1000)
      }
      return () => {
        clearInterval(intervalRef.current!)
      }
    }, [timerHasStarted])

    // Handle timer reaching zero and displaying the modal
    useEffect(() => {
      if (time <= 0 && !continueFromZero) {
        setShowModal(true)
        setTime(0)
      }
      //   else if (continueFromZero && time <= -duration) {
      //     console.log('true')
      //     onCapture()
      //   }
    }, [time, continueFromZero, duration, onCapture])

    //  Capture images at regular intervals
    useEffect(() => {
      //   const interval = (duration / allowedImages) * 1000
      const adjustedDuration = duration - 10 // Adjust duration for capture intervals
      const interval = (adjustedDuration * 1000) / allowedImages

      let count = 0
      captureIntervalRef.current = setInterval(() => {
        if (count < allowedImages) {
          onCapture()
          count++
        } else {
          clearInterval(captureIntervalRef.current!)
        }
      }, interval)

      return () => {
        clearInterval(captureIntervalRef.current!)
      }
    }, [duration, onCapture, allowedImages])

    // Function to handle continuing the assessment
    const handleContinue = () => {
      setShowModal(false)
      setContinueFromZero(true)
    }

    // Function to handle declining the assessment
    const handleCancel = () => {
      clearInterval(intervalRef.current!)
      setTime(0)
      handleAssessmentDecline()
      !isLoadingDeclineAssessment && setShowModal(false)
    }

    // Function to stop the timer
    const handleStop = () => {
      clearInterval(intervalRef.current!)
      setTime(time)
    }

    // Function to format the time into minutes and seconds
    const formatTime = (seconds: number): string => {
      const absSeconds = Math.abs(seconds)
      const minutes = Math.floor(absSeconds / 60)
      const remainingSeconds = absSeconds % 60
      const formattedMinutes = String(minutes).padStart(2, '0')
      const formattedSeconds = String(remainingSeconds).padStart(2, '0')
      const formattedTime = `${
        seconds < 0 ? '-' : ''
      }${formattedMinutes}:${formattedSeconds}`
      setFormattedTime(formattedTime)
      return formattedTime
    }

    return (
      <>
        {!typingTest ? (
          <div className='w-1/5 my-5 bg-white rounded-lg p-4 border-2 border-gray-300 border-dashed'>
            <div className='flex flex-col justify-between items-center'>
              <span className='text-sm font-medium text-gray-700'>
                Time Remaining
              </span>
              <span className='bg-[#FEF3C7] text-[#92400E] px-4 py-1 rounded-full'>
                {formatTime(time)}
              </span>
            </div>
          </div>
        ) : (
          <div
            className={cn(
              ' flex flex-row items-center justify-center gap-2  px-4 py-1 rounded-full ',
              {
                'bg-Yellow-100': time > 0,
                'bg-Red-100': time <= 0,
              },
            )}>
            <i
              className={cn(
                'ri-checkbox-blank-circle-fill w-2 h-2  flex items-center justify-center',
                {
                  'text-Yellow-400': time > 0,
                  'text-Red-400': time <= 0,
                },
              )}
            />
            <span
              className={cn({
                'text-Yellow-800': time > 0,
                'text-Red-800': time <= 0,
              })}>
              {formatTime(time)}
            </span>
          </div>
        )}

        {showModal && (
          <ReachedTimeModal
            showModal={showModal}
            primaryAction={handleContinue}
            secondaryAction={handleCancel}
            primaryBtnName='Continue Assessment'
            secondaryBtnName='Decline to Complete'
            closeButtonAction={handleContinue}
            isSavingAssessment={isSavingAssessment}
            isLoadingDeclineAssessment={isLoadingDeclineAssessment}
          />
        )}
      </>
    )
  },
)

export default Timer
