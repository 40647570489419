import { createSlice } from '@reduxjs/toolkit'

export interface WordState {
  word: {
    currWord: string
    typedWord: string
    typedHistory: string[]
    wordList: string[]
    activeWordRefId: string | undefined
  }
}

const initialState: WordState = {
  word: {
    currWord: '',
    typedWord: '',
    typedHistory: [],
    wordList: [],
    activeWordRefId: undefined,
  },
}

const wordSlice = createSlice({
  name: 'word',
  initialState: initialState.word,
  reducers: {
    setChar(state, action) {
      state.typedWord = action.payload
    },
    setWord(state, action) {
      state.typedHistory.push(action.payload)
    },
    appendTypedHistory(state) {
      const nextIdx = state.typedHistory.length + 1
      state.typedHistory.push(state.typedWord)
      state.typedWord = ''
      state.currWord = state.wordList[nextIdx]
    },
    backtrackWord(state) {
      const prevIdx = state.typedHistory.length - 1
      state.currWord = state.wordList[prevIdx]
      state.typedWord = state.typedHistory[prevIdx]
      state.typedHistory = state.typedHistory.slice(0, -1)
    },
    setActiveWordRefId(state, action) {
      state.activeWordRefId = action.payload
    },
    setWordList(state, action) {
      let wordList: string[] = action.payload.split(' ')
      const areNotWords = wordList.some((word: string) => word.includes(' '))
      if (areNotWords) {
        wordList = action.payload.flatMap((token) => token.split(' '))
      }
      state.typedWord = ''
      state.typedHistory = []
      state.currWord = wordList[0]
      state.wordList = wordList
    },
  },
})

export const {
  setChar,
  setWord,
  appendTypedHistory,
  setActiveWordRefId,
  setWordList,
  backtrackWord,
} = wordSlice.actions
export default wordSlice.reducer
