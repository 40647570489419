/* Layouts */
import { MainLayout } from 'components/layouts/MainLayout'
import {
  AlreadySubmitted,
  AssessmentAlreadySubmitted,
  IncorrectInformation,
  TokenExpired,
} from 'components/pages'
import { AssessmentDetails } from 'components/pages/Assessment/AssessmentDetails'
import { CandidateForm } from 'components/pages/CandidateForm'
import { CandidateSurvey } from 'components/pages/CandidateSurvey'
import { CareerInterestApplication } from 'components/pages/CareerInterestApplication'
import { Error404 } from 'components/pages/Error404'
import {
  CandidateDetails,
  PhoneRefCheckModal,
  Questionnaire,
} from 'components/pages/referenceCheck'
import { TalentRequestForm } from 'components/pages/TalentRequestForm'
import { AssessmentThankYou, ThankYou } from 'components/pages/ThankYou'
import { PhoneRefCheckThankYou } from 'components/pages/ThankYou/PhoneRefCheckThankYou'

interface Route {
  path: string
  component?: any
  heading?: string
  subHeading?: string
  layout?: any
  redirectTo?: string
  isSecured?: boolean
  isAuthScreen?: boolean
}

export const routes: Route[] = [
  {
    path: '/candidate/:cid',
    component: CandidateForm,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/talent-request',
    component: TalentRequestForm,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/remind',
    component: CandidateForm,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/surveys/:surveyId',
    component: CandidateSurvey,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/surveys/:surveyId/remind',
    component: CandidateSurvey,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/surveys/:surveyId/thankyou',
    component: ThankYou,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid',
    component: CandidateDetails,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/remind',
    component: CandidateDetails,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/questionnaire',
    component: Questionnaire,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/assessment/:aid',
    component: AssessmentDetails,
    // layout: MainLayout,
  },
  {
    path: '/candidate/:cid/assessment/:aid/remind',
    component: AssessmentDetails,
    // layout: MainLayout,
  },
  {
    path: '/candidate/:cid/assessment/:aid/thankyou',
    component: AssessmentThankYou,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/incorrect',
    component: IncorrectInformation,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/remind/incorrect',
    component: IncorrectInformation,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/incorrect/thankyou',
    component: ThankYou,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/remind/incorrect/thankyou',
    component: ThankYou,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/thankyou',
    component: ThankYou,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/referee/:rid/remind/thankyou',
    component: ThankYou,
    layout: MainLayout,
  },
  {
    path: '/phonerefcheck/candidate/:cid/referee/:rid',
    component: PhoneRefCheckModal,
    layout: MainLayout,
  },
  {
    path: '/phonerefcheck/candidate/:cid/referee/:rid/confirmed',
    component: PhoneRefCheckThankYou,
    layout: MainLayout,
  },
  {
    path: '/candidate/:cid/career-interest-application',
    component: CareerInterestApplication,
    layout: MainLayout,
  },
  {
    path: '/already-submitted',
    component: AlreadySubmitted,
    isSecured: true,
  },
  {
    path: '/assessment-already-submitted',
    component: AssessmentAlreadySubmitted,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/token-expired',
    component: TokenExpired,
    isSecured: true,
  },
  {
    path: '*',
    component: Error404,
    isSecured: true,
  },
]

export default routes
