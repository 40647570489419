import { useEffect, useRef, useState } from 'react'

const useOutsideMouseTracker = () => {
  // State for tracking time and conditions
  const [timeOutside, setTimeOutside] = useState(0)
  const [isOutOfTheFullScreen, setIsOutOfTheFullScreen] = useState(false)

  // Refs for tracking various states and time
  const timerRef = useRef<NodeJS.Timer | null>(null)
  const startTimeRef = useRef<number | null>(null)
  const isMouseOutside = useRef(false)
  const isPageVisible = useRef(true)
  const isPageFocused = useRef(true)
  const isCurrentPage = useRef(true) // New ref to track if current page is active

  // function to start the timer
  const startTracking = () => {
    if (!timerRef.current) {
      startTimeRef.current = Date.now()
      timerRef.current = setInterval(() => {
        setTimeOutside((prev) => prev + 1)
      }, 1000)
    }
  }

  // function to stop the timer
  const stopTracking = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }

  // Effect to handle mouse events
  useEffect(() => {
    const handleMouseLeave = (e: MouseEvent) => {
      // Check if mouse actually left the window
      if (
        e.clientY <= 0 ||
        e.clientY >= window.innerHeight ||
        e.clientX <= 0 ||
        e.clientX >= window.innerWidth
      ) {
        isMouseOutside.current = true
        if (
          isPageVisible.current &&
          isPageFocused.current &&
          isCurrentPage.current
        ) {
          startTracking()
        }
      }
    }

    const handleMouseEnter = () => {
      isMouseOutside.current = false
      if (!isOutOfTheFullScreen && isCurrentPage.current) {
        stopTracking()
      }
    }

    document.addEventListener('mouseleave', handleMouseLeave)
    document.addEventListener('mouseenter', handleMouseEnter)

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave)
      document.removeEventListener('mouseenter', handleMouseEnter)
    }
  }, [isOutOfTheFullScreen])

  // Effect to handle visibility and focus
  useEffect(() => {
    const handleVisibilityChange = () => {
      isPageVisible.current = !document.hidden
      isCurrentPage.current = !document.hidden // Update current page status

      if (document.hidden) {
        startTracking() // Start timer when page becomes hidden
      } else {
        if (!isMouseOutside.current && !isOutOfTheFullScreen) {
          stopTracking() // Stop timer when returning to page
        }
      }
    }

    const handleFocus = () => {
      isPageFocused.current = true
      isCurrentPage.current = true // Update current page status
      if (!isMouseOutside.current && !isOutOfTheFullScreen) {
        stopTracking()
      }
    }

    const handleBlur = () => {
      isPageFocused.current = false
      isCurrentPage.current = false // Update current page status
      startTracking() // Start timer when focus is lost
    }

    // Handle page visibility changes
    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Handle window focus changes
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [isOutOfTheFullScreen])

  // Effect to handle fullscreen changes
  useEffect(() => {
    if (
      isOutOfTheFullScreen &&
      isPageVisible.current &&
      isPageFocused.current &&
      isCurrentPage.current
    ) {
      startTracking()
    } else if (
      !isOutOfTheFullScreen &&
      !isMouseOutside.current &&
      isCurrentPage.current
    ) {
      stopTracking()
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [isOutOfTheFullScreen])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(
      2,
      '0',
    )}`
  }

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [])

  return {
    timeOutside,
    formatTime,
    setIsOutOfTheFullScreen,
    isTracking: timerRef.current !== null,
  }
}

export default useOutsideMouseTracker
