import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

type SubmitAssessmentRequest = {
  candidateId: string
  assessmentId: string
  assessmentResponseId: string
  examCompletionTime: number
  mouseOutOfScreen: number
  jsonData: string | null
}

export const useSubmitAssessment = () => {
  return useMutation(
    ({
      candidateId,
      assessmentId,
      assessmentResponseId,
      jsonData,
      examCompletionTime,
      mouseOutOfScreen,
    }: SubmitAssessmentRequest) => {
      return postAPI(
        `/assessment/candidates/${candidateId}/assessment/${assessmentId}/submit`,
        {
          assessmentResponseId,
          jsonData,
          mouseOutOfScreen,
          examCompletionTime,
        },
      )
    },
    {
      onError: () => {
        Toast({
          alertHeader: 'Error submitting assessment',
          status: 'Error',
        })
      },
    },
  )
}
