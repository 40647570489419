import { useState } from 'react'

type ImageViewerProps = {
  imageUrl: string
}
const ImageViewer = ({ imageUrl }: ImageViewerProps) => {
  const [loading, setLoading] = useState(true)
  return (
    <div className='w-1/2 flex justify-center mr-4'>
      {loading && (
        <div className='flex items-center justify-center h-[200px] w-full'>
          <div className='w-12 h-12 border-4 border-Blue-500 border-t-transparent border-solid rounded-full animate-pulse200'></div>
        </div>
      )}
      <img
        src={imageUrl}
        alt='drag-and-drop-area'
        className={`rounded max-h-[200px] w-auto ${
          loading ? 'opacity-0' : 'opacity-100'
        }`}
        onLoad={() => setLoading(false)}
      />
    </div>
  )
}

export default ImageViewer
