import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { recordTest } from 'helpers/recordTest'
import { calculateWpmAndRaw } from 'helpers/testStats'
import { RootState } from 'store/reducers'
import { setActiveWordRefId } from 'store/reducers/typeTest/wordSlice'

import Timer, { TimerHandle, TimerProps } from '../Timer/Timer'

type TypeData = {
  id: number
  time: number
  wpm: number
  raw: number
  error: number | null
}

export default function TypeTestAddon({
  duration,
  allowedImages,
  onCapture,
  setFormattedTime,
  isSavingAssessment,
  handleAssessmentDecline,
  isLoadingDeclineAssessment,
  addTemplatedFormCheck,
  addonSubmitOnExit,
}: TimerProps & {
  addTemplatedFormCheck: (jsonData: string) => void
  addonSubmitOnExit: boolean
}) {
  const {
    word: { typedWord, currWord, wordList, typedHistory, activeWordRefId },
  } = useSelector((state: RootState) => state)

  const dispatch = useDispatch()
  const timerRef = useRef<TimerHandle>(null)

  const extraLetters = typedWord.slice(currWord?.length).split('')
  const activeWord = useRef<HTMLDivElement>(null)
  const caretRef = useRef<HTMLSpanElement>(null)
  const numOfErrors = useRef(0)
  const chartDataArray = useRef<TypeData[]>([])
  const [timerHasStarted, setTimerHasStarted] = useState(false)

  const handleSubmitData = () => {
    const completeTypedHistory =
      typedWord === '' ? typedHistory : [...typedHistory, typedWord]
    const data = {
      wordList,
      typedHistory: completeTypedHistory,
      chartDataArray: chartDataArray.current,
    }
    addTemplatedFormCheck(JSON.stringify(data))
  }
  // const [, setKey] = useState('')
  useEffect(() => {
    if (isSavingAssessment && timerRef.current) {
      timerRef.current.handleStop() // Trigger handleCancel on the Timer component
    }
  }, [isSavingAssessment])

  useEffect(() => {
    if (addonSubmitOnExit) {
      handleSubmitData()
    }
  }, [addonSubmitOnExit])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key.length === 1 || e.key === 'Backspace') {
        !timerHasStarted ? setTimerHasStarted(true) : null
        recordTest(e.key)
        // setKey(e.key)
        e.preventDefault()
      }
      dispatch(setActiveWordRefId(activeWord?.current?.id))
    }

    document.onkeydown = handleKeyDown
    return () => {
      document.onkeydown = null
    }
  }, [dispatch])

  useEffect(() => {
    const startTimer = duration - (timerRef?.current?.time || 0)

    if (
      timerRef?.current?.intervalRef?.current &&
      timerRef?.current?.time > 0
    ) {
      const { wpm = 0, raw = 0 } = calculateWpmAndRaw({
        wordList,
        typedLIst: typedHistory,
        testSeconds: startTimer,
      })

      const typeData = {
        id: startTimer,
        time: startTimer,
        wpm: wpm || 0,
        raw: raw || 0,
        error: numOfErrors.current !== 0 ? numOfErrors.current : null,
      }

      // Only push if data with same id doesn't exist
      if (!chartDataArray.current.some((item) => item.id === typeData.id)) {
        chartDataArray.current.push(typeData)
      }
      numOfErrors.current = 0
    }

    if ((timerRef?.current?.time || 0) <= 0) {
      handleSubmitData()
    }
  }, [timerRef?.current?.time])

  // setting character style based on the right or wrong input
  useEffect(() => {
    if (typedHistory.length != 0 && typedHistory.length >= wordList.length) {
      handleSubmitData()
      return
    }
    const idx = typedWord.length - 1
    const currWordEl = activeWord?.current

    if (currWord[idx] && currWordEl) {
      currWordEl.children[idx + 1].classList.add(
        currWord[idx] !== typedWord[idx] ? 'wrong' : 'right',
      )
      if (currWord[idx] !== typedWord[idx]) {
        numOfErrors.current += 1
      }
    }
  }, [currWord, typedWord, activeWordRefId])

  const getCaretPosition = () => {
    if (!typedWord.length) return 0
    const left =
      typedWord.length < currWord.length
        ? (activeWord?.current?.childNodes[typedWord.length] as HTMLElement)
            ?.offsetLeft
        : (
            activeWord?.current?.childNodes[
              activeWord?.current?.childNodes.length - 1
            ] as HTMLElement
          )?.offsetLeft
    const lastWidth =
      typedWord.length <= currWord.length
        ? (
            activeWord?.current?.childNodes[typedWord.length] as HTMLElement
          )?.getBoundingClientRect()?.width
        : 8

    const position = left + lastWidth
    return position
  }

  return (
    <>
      <p className='bg-Cobalt-50 flex text-small font-SemiBold flex-col h-fit my-5 p-4'>
        Typing Speed (Lowercase Only)
      </p>

      <div className=' bg-white shadow-lg rounded-2xl  bg-gray-100 w-full h-fit pb-6'>
        <div className='flex flex-row  justify-between items-center m-6'>
          <p className='text-heading-5 font-Medium mt-6 mb-3'>
            The test has started. Type as accurate as possible until the time
            runs out{' '}
          </p>
          <Timer
            ref={timerRef}
            typingTest={true}
            duration={duration}
            timerHasStarted={timerHasStarted}
            allowedImages={allowedImages}
            onCapture={onCapture}
            setFormattedTime={setFormattedTime}
            isSavingAssessment={isSavingAssessment}
            handleAssessmentDecline={handleAssessmentDecline}
            isLoadingDeclineAssessment={isLoadingDeclineAssessment}
          />
        </div>
        <div className=' mx-6 p-6  border-2 border-gray-600 text-[25px] h-[170px] leading-10  text-sm  bg-white  overflow-hidden  flex flex-wrap  select-none gap-2 styled-scroll'>
          {wordList.map((word, idx) => {
            const isActive = currWord === word && typedHistory.length === idx

            return (
              <div
                key={word + idx}
                className='relative mt-0 text-base h-fit '
                id={word + idx}
                ref={isActive ? activeWord : null}>
                {isActive ? (
                  <span
                    ref={caretRef}
                    id='caret'
                    className='transition-[left] -ml-1 text-[25px] leading-10  absolute  animate-cursor duration-150 font-bold blink'
                    style={{
                      left: getCaretPosition(),
                    }}>
                    |
                  </span>
                ) : null}
                {word?.split('')?.map((char, charId) => {
                  return (
                    <span
                      key={char + charId}
                      className='text-Gray-400 text-[25px] leading-10 '>
                      {char}
                    </span>
                  )
                })}
                {isActive
                  ? extraLetters?.map((char, charId) => {
                      return (
                        <span
                          key={char + charId}
                          className='wrong extra text-[25px] leading-10 '>
                          {char}
                        </span>
                      )
                    })
                  : typedHistory[idx]
                  ? typedHistory[idx]
                      ?.slice(wordList[idx].length)
                      ?.split('')
                      ?.map((char, charId) => {
                        return (
                          <span
                            key={char + charId}
                            className='wrong extra  text-[25px] leading-10 '>
                            {char}
                          </span>
                        )
                      })
                  : null}
              </div>
            )
          })}
        </div>
      </div>
      {/* <Button
        isDisabled={!timerHasStarted}
        className='my-8 ml-auto'
        onClick={handleSubmitData}>
        Finish
      </Button> */}
    </>
  )
}
