import { ListAssessmentResponse } from 'framework/api/methods/assessment/useGetInitialAssessmentDetailsById'

import { assessmentTypes } from 'utils/assessmentTypes'

type MainContentProps = {
  initialAssessment: ListAssessmentResponse | null
  privacyPolicyUrl: string | null | undefined
}

const MainContent = ({
  initialAssessment,
  privacyPolicyUrl,
}: MainContentProps) => {
  return (
    <ul className='list-disc pl-4'>
      <li className='mt-3 font-Regular text-black'>
        You will have{' '}
        <span className='font-SemiBold'>
          {initialAssessment?.duration} minutes
        </span>{' '}
        to complete the assessment once you click
        <span className='font-SemiBold'> Continue</span>.
      </li>
      <li className='mt-3 font-Regular text-black'>
        Ensure you are in a quiet and distraction-free environment conducive to
        completing the assessment.
      </li>
      {initialAssessment?.isWebCamMandatory === true && (
        <li className='mt-3 font-Regular text-black'>
          A webcam is required to verify your identify during the assessment. We
          only capture screenshots for identity verification purposes; no audio
          is recorded.
        </li>
      )}
      {initialAssessment?.isWebCamMandatory === false &&
        !initialAssessment.isAddOn && (
          <li className='mt-3 font-Regular text-black'>
            Although not required, a webcam is recommended to prove your
            identify during the assessment. We only capture screenshots for
            identity verification purposes; no audio is recorded.
          </li>
        )}

      {initialAssessment?.assessmentName === assessmentTypes.TYPING_TEST && (
        <li className='mt-3 font-Regular text-black'>
          You are required to use a full-size keyboard to complete the typing
          test.
        </li>
      )}

      <li className='mt-3 font-Regular text-black'>
        Submitted answers are final and cannot be modified.
      </li>
      <li className='mt-3 font-Regular text-black'>
        You must remain within the assessment window for the entire duration.
        You must not use Google or any other tools during the assessment. We
        monitor response behavior to ensure fairness and prevent cheating.
      </li>
      {privacyPolicyUrl !== null && (
        <li className='mt-3 font-Regular text-black'>
          By continuing, you accept our{' '}
          <a
            className='text-[#4285F4] underline'
            href={privacyPolicyUrl}
            target='_blank'>
            data privacy policy
          </a>
          .
        </li>
      )}
    </ul>
  )
}

export default MainContent
